<template>
  <div>
    <ListOfReport />
  </div>
</template>

<script>
import ListOfReport from "../components/listOfReport/index.vue";
export default {
  components: {
    ListOfReport,
  }
};
</script>

<style>
</style>
<template>
  <div>
    <div class="d-flex justify-content-end d-lg-none">
      <button
        @click="
          showFilter = true;
          showFilterWeb = true;
        "
        class="d-flex btn btn-info"
      >
        <i class="material-icons-outlined">filter_alt</i> Advanced Filter
      </button>
    </div>
    <div v-if="showFilter" class="filter-on-dashboard card">
      <div class="card-title px-4 d-flex justify-content-between my-4">
        <p @click="showFilterWeb = !showFilterWeb">Advanced Filters</p>
        <i class="material-icons-outlined d-lg-none" @click="showFilter = false"
          >close</i
        >
        <i
          class="material-icons-outlined d-none d-lg-inline"
          @click="showFilterWeb = !showFilterWeb"
          >{{ showFilterWeb ? "arrow_downward" : "arrow_forward" }}</i
        >
      </div>
      <div class="card-body" v-if="showFilterWeb">
        <div class="row justify-content-center">
          <div class="col-6 my-2 col-md-4 col-xl-4 col-lg-3">
            <div class="form-group">
              <input
                class="form-control"
                v-model="date"
                required
                type="date"
                aria-describedby="emailHelp"
                placeholder="Enter Created Date"
              />
            </div>
          </div>
          <div class="col-12 my-2 col-md-4 col-xl-4 col-lg-3">
            <v-select
              ref="serialNumbers"
              :searchable="false"
              label="description"
              class="style-chooser"
              :value="activeReport.status ? activeReport : null"
              @option:selected="SET_SELECTED_FORM_TYPE"
              :clearable="false"
              placeholder="Search form type"
              :options="reports"
            >
            </v-select>
          </div>
          <div class="col-12 my-2 col-md-4 col-xl-4 col-lg-3">
            <v-select
              @search="GET_COMPANIES_LIST"
              label="name"
              class="style-chooser"
              @option:selected="SET_SELECTED_COMPANY"
              :value="activeCompany.status ? activeCompany : null"
              :clearable="false"
              placeholder="Search company name"
              :options="companies"
            ></v-select>
          </div>
          <div class="col-12 my-2 col-md-4 col-xl-4 col-lg-3">
            <v-select
              @search="GET_BRAND_LIST"
              label="name"
              class="style-chooser"
              :value="activeBrand.status ? activeBrand : null"
              ref="brandSelect"
              @option:selected="SET_SELECTED_BRAND"
              :clearable="false"
              placeholder="Search brand name"
              :options="brands"
            >
              ></v-select
            >
          </div>
          <div class="col-12 my-2 col-md-4 col-xl-4 col-lg-3">
            <v-select
              @search="GET_PRODUCT_LIST"
              ref="productSelect"
              label="model"
              class="style-chooser"
              :value="activeProduct.status ? activeProduct : null"
              @option:selected="SET_SELECTED_PRODUCT"
              :clearable="false"
              placeholder="Search Model Number"
              :options="products"
            ></v-select>
          </div>
          <div class="col-12 my-2 col-md-4 col-xl-4 col-lg-3">
            <v-select
              @search="GET_SERIAL_NUMBER_LIST"
              ref="serialNumbers"
              label="serialNo"
              class="style-chooser"
              :value="!activeSerialNumber.status ? null : activeSerialNumber"
              @option:selected="SET_SELECTED_SERIAL_NUMBER"
              :clearable="false"
              placeholder="Search serial number"
              :options="serialNumbers"
            >
            </v-select>
          </div>
        </div>
        <div class="row justify-content-end mt-4">
          <button
            v-if="isFiltered"
            @click="SET_REMOVE_FILTER(false)"
            class="btn mr-2 btn-lg btn-danger"
          >
            Remove all Filter
          </button>
          <button
            class="btn btn-lg btn-primary"
            @click="
              GET_REPORT_LIST_WITH_FILTER();
              GET_REPORT_LIST_WITH_FILTER(0, 0);
            "
          >
            Apply Filter
          </button>
        </div>
      </div>
      <div class="card-body text-center" v-else>
        <p>
          <i>Show Advanced Filter in below</i>
        </p>
        <div class="mt-2">
          <button @click="showFilterWeb = true" class="btn btn-info btn-xs">
            Show Filter
          </button>
        </div>
      </div>
    </div>
    <div class="text-right my-4" v-if="!loading">
      <div class="row justify-content-end">
        <div class="col-12 my-2 col-md-4 col-xl-4 col-lg-3">
          <v-select
            ref="serialNumbers"
            :searchable="false"
            label="text"
            class="style-chooser"
            :value="activeSort"
            @option:selected="SET_SORT"
            :clearable="false"
            placeholder="Select Sort"
            :options="sorts"
          >
          </v-select>
        </div>
      </div>
      <h6>
        <span class="text-info">
          <i>{{ allItemsCount + allUndoneItemsCount }} reports found</i>
        </span>
      </h6>
    </div>
    <!-- FILTRELEME ITEMS -->
    <div
      v-if="isFiltered"
      class="my-4 d-flex align-items-center"
      style="flex-flow: row wrap"
    >
      <span
        @click="
          activeReport = { status: false, id: 0 };
          SET_SORT(activeSort);
        "
        v-if="activeReport.status"
        class="filter-badge badge badge-pill align-items-center badge-info m-2"
      >
        Report: {{ activeReport.description }}
        <i class="ml-2 material-icons-outlined">close</i>
      </span>
      <span
        @click="
          activeCompany = { status: false, id: 0 };
          SET_SORT(activeSort);
        "
        v-if="activeCompany.status"
        class="
          filter-badge
          badge badge-pill
          align-items-center
          badge-warning
          m-2
        "
      >
        Company: {{ activeCompany.name }}
        <i class="ml-2 material-icons-outlined">close</i>
      </span>
      <span
        @click="
          activeBrand = { status: false, id: 0 };
          SET_SORT(activeSort);
        "
        v-if="activeBrand.status"
        class="
          filter-badge
          badge badge-pill
          align-items-center
          badge-success
          m-2
        "
      >
        Brand: {{ activeBrand.name }}
        <i class="ml-2 material-icons-outlined">close</i>
      </span>
      <span
        @click="
          activeProduct = { status: false, id: 0 };
          SET_SORT(activeSort);
        "
        v-if="activeProduct.status"
        class="
          filter-badge
          badge badge-pill
          align-items-center
          badge-danger
          m-2
        "
      >
        Product: {{ activeProduct.model }}
        <i class="ml-2 material-icons-outlined">close</i>
      </span>
      <span
        @click="
          activeSerialNumber = { status: false, id: 0 };
          SET_SORT(activeSort);
        "
        v-if="activeSerialNumber.status"
        class="
          filter-badge
          badge badge-pill
          align-items-center
          badge-secondary
          m-2
        "
      >
        Serial No: {{ activeSerialNumber.serialNo }}
        <i class="ml-2 material-icons-outlined">close</i>
      </span>
      <span
        @click="
          date = '';
          SET_SORT(activeSort);
        "
        v-if="date !== ''"
        class="filter-badge badge badge-pill align-items-center badge-dark m-2"
      >
        <span class="d-inline-block p-2"> CreatedAt: {{ date }} </span>
        <i class="ml-2 material-icons-outlined">close</i>
      </span>
    </div>
    <!-- TAMAMLANMAMIŞ RAPORLAR -->
    <div
      class="d-flex align-items-center card-title text-warning"
      v-if="allUndoneItemsCount !== 0"
    >
      <i style="font-size: 19px" class="mr-2 material-icons-outlined"
        >warning_amber</i
      >
      Incomplete Reports ({{ allUndoneItemsCount }})
      <button
        @click="showUndoneReports = !showUndoneReports"
        :class="{
          'btn-warning': showUndoneReports,
          'btn-success': !showUndoneReports,
        }"
        class="d-flex align-items-center ml-2 btn btn-xs"
      >
        <i style="font-size: 19px" class="mr-2 material-icons-outlined"
          >visibility{{ !showUndoneReports ? "" : "_off" }}</i
        >
        {{ showUndoneReports ? "Hide" : "Show" }}
      </button>
    </div>

    <div v-if="showUndoneReports && allUndoneItemsCount !== 0">
      <p class="text-right">
        <i>* Last 10 Incomplete Report shows.</i>
      </p>
      <CardTable
        :allItemsCount="allUndoneItemsCount"
        :loading="loadingUndone"
        @export:pdf="GET_REPORT_DETAIL_THEN_EXPORT_PDF"
        @change="SET_PAGINATION"
        :listOfReports="listOfUndoneReports"
      />
    </div>
    <!-- TAMAMLANMIŞ RAPORLAR -->
    <div class="d-flex align-items-center card-title text-success mt-2">
      <i style="font-size: 19px" class="mr-2 material-icons-outlined"
        >check_circle</i
      >
      Completed Reports ({{ allItemsCount }})
      <button
        @click="showReports = !showReports"
        :class="{
          'btn-warning': showReports,
          'btn-success': !showReports,
        }"
        class="d-flex align-items-center ml-2 btn btn-xs"
      >
        <i style="font-size: 19px" class="mr-2 material-icons-outlined"
          >visibility{{ !showReports ? "" : "_off" }}</i
        >
        {{ showReports ? "Hide" : "Show" }}
      </button>
    </div>
    <div v-if="showReports">
      <div class="d-flex justify-content-end my-4" v-if="allItemsCount !== 0">
        <button
          @click="
            isFiltered
              ? GET_REPORT_LIST_WITH_FILTER(1, 1)
              : GET_REPORT_LIST_WITHOUT_FILTER(1, 1)
          "
          class="btn btn-success d-flex btn-lg"
        >
          <i class="mr-2 material-icons-outlined">file_download</i> Export to
          Excel ({{ allItemsCount }} item{{ allItemsCount > 1 ? "s" : "" }})
        </button>
      </div>
      <CardTable
        :allItemsCount="allItemsCount"
        :loading="loading"
        :listOfReports="listOfReports"
        :page="page"
        @change="SET_PAGINATION"
        :pages="pages"
        @export:pdf="GET_REPORT_DETAIL_THEN_EXPORT_PDF"
      />
      <Modal
        no-close
        hide-footer
        title="Export Excel File"
        v-model="excelModal"
      >
        <div class="card-body">
          <div class="text-center" v-if="excelSuccessMessage">
            <i
              style="font-size: 72px"
              class="material-icons-outlined text-primary"
              >check</i
            >
            <p class="text-center mt-4">
              Download started. Please check your
              <span class="text-primary"> downloaded folder of device.</span>
            </p>
          </div>
          <div v-else-if="excelModalLoading" class="text-center">
            <span
              class="spinner-border mr-2 text-primary"
              role="status"
              aria-hidden="true"
            ></span>
            <div class="mt-4 text-primary">
              <strong> Creating excel file... Please Wait.. </strong>
            </div>
            <div class="mt-4">
              <i>This process may take a while</i>
            </div>
          </div>
          <div v-else class="my-4">
            <div class="card-title">
              If you want to change Excel File Name, You're able to update below
            </div>
            <div class="form-group">
              <label for="name">File Name</label>
              <span class="ml-2 badge badge-danger">required</span>
              <input
                class="form-control"
                :class="{ 'border-danger': filename === '' }"
                id="name"
                maxlength="50"
                v-model="filename"
                required
                aria-describedby="emailHelp"
                placeholder="Enter Filename"
              />
              <div v-if="filename === ''">
                <small class="text-danger"> Filename is required</small>
              </div>
            </div>
            <div class="card-title">
              which fields do you want to add to Excel Sheet?
            </div>
            <Checkbox
              :value="isSelectedAll"
              color="#5fd0a5"
              @change="SET_EXCEL_SELECT_ALL"
              ><strong>Select All</strong></Checkbox
            >
            <hr />
            <Checkbox
              :value="option"
              v-for="(option, i) in excelColumns"
              :key="i"
              color="#5fd0a5"
              v-model="excelColumnsValues"
              >{{ option.label }}</Checkbox
            >
            <div class="mt-4 d-flex justify-content-center">
              <ExportExcell
                :filename="filename"
                class="btn btn-primary btn-block d-flex justify-content-center"
                :columns="excelColumnsValues"
                :count="allItemsCount"
                :items="excelAllData"
              />
            </div>
            <div class="mt-5 text-center">
              <a href="javascript:void(0)" @click="excelModal = false">
                Close
              </a>
            </div>
          </div>
        </div>
      </Modal>
      <PdfLoadingModal
        :loadingDetailModal="loadingDetailModal"
      ></PdfLoadingModal>
      <Modal v-model="pdfViewModal">
       <iframe :src="pdfUrl" width="100%" height="800px" frameborder="0"></iframe>
      </Modal>
      <Modal hide-footer v-model="isMobileDownloadStarted.status">
        <div class="card-body">
          <div class="text-center">
            <i
              v-if="isMobileDownloadStarted.process"
              style="font-size: 72px"
              class="material-icons-outlined text-primary"
              >download</i
            ><i
              v-else
              style="font-size: 72px"
              class="material-icons-outlined text-primary"
              >check</i
            >
            <p
              class="text-center mt-4"
              v-html="isMobileDownloadStarted.message"
            ></p>
          </div>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import BrandActions from "../createReport/chooseCompany/chooseProduct/brandActions";
import ProductActions from "../createReport/chooseCompany/chooseProduct/productActions";
import SerialNumberActions from "../createReport/chooseCompany/chooseProduct/serialNumberActions";
import CompanyActions from "../createReport/chooseCompany/companyActions";
import ReportTypeActions from "../reportType/getReportType";
import ExportExcell from "../Exports/ExportExcell.vue";
import Modal from "../ui/modal.vue";
import Checkbox from "vue-material-checkbox";
import ExportPDF from "../Exports/exportPDF";
import CardTable from "../ui/table/cardTableReports.vue";
import PdfLoadingModal from "../shared/modal/PdfLoadingModal.vue";
export default {
  components: {
    Modal,
    CardTable,
    ExportExcell,
    Checkbox,
    PdfLoadingModal,
  },
  mixins: [
    ReportTypeActions,
    BrandActions,
    SerialNumberActions,
    CompanyActions,
    ProductActions,
    ExportPDF,
  ],
  data() {
    return {
      isFiltered: false,
      showReports: true,
      showUndoneReports: true,
      page: 1,
      limit: 10,
      allItemsCount: 0,
      allUndoneItemsCount: 0,
      showFilter: false,
      showFilterWeb: true,
      date: "",
      activeSort: { value: "date-desc", text: "Sort by Date (Z-A)" },
      listOfReports: [],
      listOfUndoneReports: [],
      loadingUndone: true,
      loading: true,
      headers: [{ label: "Date/Type" }, { label: "Company/Product" }],
      sorts: [
        { value: "date-asc", text: "Sort by Date (A-Z)" },
        { value: "date-desc", text: "Sort by Date (Z-A)" },
        { value: "company-name-asc", text: "Sort by Company Name (A-Z)" },
        { value: "company-name-desc", text: "Sort by Company Name (Z-A)" },
        { value: "brand-asc", text: "Sort by Brand Name (A-Z)" },
        { value: "brand-desc", text: "Sort by Brand Name (Z-A)" },
      ],
      // excel actions
      excelColumns: [],
      filename: "",
      excelColumnsValues: [],
      excelAllData: [],
      excelModal: false,
      excelModalLoading: true,
      excelSuccessMessage: false,
    };
  },
  watch: {
    excelModalLoading(data) {
      if (!data) {
        setTimeout(() => {
          const vm = this;
          document
            .getElementById("excel-form")
            .addEventListener("click", () => {
              vm.excelSuccessMessage = true;
              vm.loading = false;
              setTimeout(() => {
                vm.excelModal = false;
                vm.excelModalLoading = false;
                vm.excelSuccessMessage = false;
              }, 2500);
            });
        }, 500);
      }
    },
  },
  computed: {
    isSelectedAll() {
      if (this.excelColumnsValues.length === this.excelAllData.length)
        return true;
      else return false;
    },
    pages() {
      return Math.ceil(this.allItemsCount / 10);
    },
  },
  methods: {
    SET_PAGINATION(page, req = false) {
      this.$router.replace({ query: { page } });
      this.page = page;
      if (req) {
        if (this.isFiltered) {
          this.GET_REPORT_LIST_WITH_FILTER(0, 1);
        } else {
          this.GET_REPORT_LIST_WITHOUT_FILTER(0, 1);
        }
      }
    },
    SET_REMOVE_FILTER(init = false) {
      this.activeReport = {
        status: false,
        id: 0,
      };
      this.activeBrand = {
        status: false,
        id: 0,
      };
      this.activeCompany = {
        status: false,
        id: 0,
      };
      this.activeProduct = {
        status: false,
        id: 0,
      };
      this.activeSerialNumber = {
        status: false,
        id: 0,
      };
      this.date = "";
      this.isFiltered = false;
      if (!init) this.SET_SORT(this.activeSort);
    },
    SET_SORT(e) {
      this.SET_PAGINATION(1);
      this.activeSort = e;
      if (this.isFiltered) {
        this.GET_REPORT_LIST_WITH_FILTER(0, 1);
        this.GET_REPORT_LIST_WITH_FILTER(0, 0);
      } else {
        this.GET_REPORT_LIST_WITHOUT_FILTER(0, 1);
        this.GET_REPORT_LIST_WITHOUT_FILTER(0, 0);
      }
    },
    SET_EXCEL_SELECT_ALL(type = true) {
      console.log(type);
      if (type) this.excelColumnsValues = [...this.excelColumns];
      else this.excelColumnsValues = [];
    },
    SET_EXCEL_FIELDS_DATA_FOR_EXPORT(response) {
      this.loading = false;
      const data = response.items;
      const excelAllData = [];
      const excelColumns = [];
      excelColumns.push(
        { label: "Created At", field: "createdAt", isActive: 1 },
        { label: "Company", field: "company", isActive: 1 },
        { label: "Address", field: "address", isActive: 1 },
        { label: "Brand", field: "brand", isActive: 1 },
        { label: "Model", field: "model", isActive: 1 },
        {
          label: "Serial Number",
          field: "serialNo",
          isActive: 1,
        },
        { label: "Arrival Time", field: "arrivalTime", isActive: 1 },
        { label: "Depart Time", field: "departTime", isActive: 1 },
        { label: "Technician Name", field: "technicianName", isActive: 1 }
      );
      data.forEach((report) => {
        const item = {
          createdAt: this.SET_DATE(report.createdAt),
          company: report.company.name,
          address: report.company.address,
          brand: report.product.brand.name,
          model: report.product.model,
          serialNo: report.product.companyProduct.serialNo,
          arrivalTime: this.SET_DATE(report.arrivalTime),
          departTime: this.SET_DATE(report.departTime),
          technicianName: report.technicianName,
        };
        report.answers.forEach((answer) => {
          const p = excelColumns.map((c) => c.label).indexOf(answer.question);
          item[`question-${answer.questionId}`] = answer.answers
            .map((ans) => ans.value)
            .join(",");
          if (p === -1) {
            excelColumns.push({
              label: answer.question,
              field: `question-${answer.questionId}`,
              isActive: 1,
            });
          }
        });
        excelAllData.push(item);
      });
      this.excelColumns = [...excelColumns];
      this.excelAllData = excelAllData;
      if (this.isFiltered)
        this.filename = `(${this.allItemsCount})${
          this.date !== "" ? "-" + this.date + "-" : ""
        }${
          this.activeReport.status
            ? "-" + this.activeReport.description + "-"
            : ""
        }${
          this.activeCompany.status ? "-" + this.activeCompany.name + "-" : ""
        }${this.activeBrand.status ? "-" + this.activeBrand.name + "-" : ""}${
          this.activeProduct.status ? "-" + this.activeProduct.model + "-" : ""
        }${
          this.activeSerialNumber.status
            ? "-" + this.activeSerialNumber.serialNo + "-"
            : ""
        }${this.SET_DATE(new Date().toISOString())}`;
      else
        this.filename = `(${this.allItemsCount})-Report-List-${this.SET_DATE(
          new Date().toISOString()
        )}`;

      this.excelModalLoading = false;
    },
    async GET_REPORT_LIST_WITH_FILTER(isExcell = 0, isCompleted = 1) {
      if (isExcell === 1) {
        this.excelModalLoading = true;
        this.excelModal = true;
      }
      this.showFilterWeb = false;
      this.showFilter = false;
      this.isFiltered = true;
      isCompleted === 1 ? (this.loading = true) : (this.loadingUndone = true);
      let url = `CompanyReports/ByFilter?isExcel=0&sortBy=${
        this.activeSort.value
      }&offset=${isCompleted === 0 ? 1 : this.page}&limit=${
        this.limit
      }&isCompleted=${isCompleted}`;
      if (isExcell === 1)
        url = `CompanyReports/ByFilter?isExcel=1&sortBy=${this.activeSort.value}&isCompleted=1`;
      const response = await this.$api.post(url, {
        reportType: this.activeReport.id,
        companyId: this.activeCompany.id == -2 ? 0 : this.activeCompany.id,
        brandId: this.activeBrand.id,
        companyProductId: this.activeSerialNumber.id,
        createdAt: this.date,
        sortBy: this.activeSort.value,
        filterAnswers: [],
      });
      if (response.result === "OK" && response.message === "OK") {
        if (isExcell === 1) {
          this.SET_EXCEL_FIELDS_DATA_FOR_EXPORT(response.data);
          this.loading = false;
          this.loadingUndone = false;
        } else if (isCompleted === 1) {
          this.listOfReports = response.data.items;
          this.allItemsCount = response.data.count;
          this.loading = false;
        } else {
          this.listOfUndoneReports = response.data.items;
          this.allUndoneItemsCount = response.data.count;
          this.loadingUndone = false;
        }
      } else {
        isCompleted === 1
          ? (this.listOfReports = [])
          : (this.listOfUndoneReports = []);
        this.loading = false;
        this.loadingUndone = false;
      }
    },
    async GET_REPORT_LIST_WITHOUT_FILTER(isExcell = 0, isCompleted = 1) {
      if (isExcell === 1) {
        this.excelModalLoading = true;
        this.excelModal = true;
      }
      this.isFiltered = false;
      isCompleted === 1 ? (this.loading = true) : (this.loadingUndone = true);
      let url = `CompanyReports?isExcel=0&sortBy=${
        this.activeSort.value
      }&offset=${isCompleted === 0 ? 1 : this.page}&limit=${
        this.limit
      }&isCompleted=${isCompleted}`;
      if (isExcell === 1)
        url = `CompanyReports?isExcel=1&sortBy=${this.activeSort.value}&isCompleted=1`;
      const response = await this.$api.get(url);
      if (response.result === "OK" && response.message === "OK") {
        if (isExcell === 1) {
          this.SET_EXCEL_FIELDS_DATA_FOR_EXPORT(response.data);
        } else if (isCompleted === 1) {
          this.listOfReports = response.data.items;
          this.allItemsCount = response.data.count;
          this.loading = false;
        } else {
          this.listOfUndoneReports = response.data.items;
          this.allUndoneItemsCount = response.data.count;
          this.loadingUndone = false;
        }
      } else {
        isCompleted === 1
          ? (this.listOfReports = [])
          : (this.listOfUndoneReports = []);
        this.loading = false;
        this.loadingUndone = false;
      }
    },
  },
  mounted() {
    this.SET_REMOVE_FILTER(true);
    if (window.innerWidth > 992) {
      this.showFilter = true;
    }
    // this.GET_REPORT_LIST_WITHOUT_FILTER(0, 0); gerek yok
    if (this.$route.query.page) this.page = Number(this.$route.query.page);
    this.GET_REPORT_LIST_WITHOUT_FILTER();
  },
};
</script>

<style>
</style>
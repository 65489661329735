import { mapGetters } from "vuex";

export default {
    data() {
        return {
            isLoadingReportList: true,
            reports: [],
            activeReport: { id: -1, description: "Report type is Loading" }
        }
    },
    computed: {
        ...mapGetters(['getReports']),
        filteredReports() {
            return this.reports.filter(r => r.isActive === 1);
        }
    },
    methods: {
        async GET_INITIAL_REPORT_LIST(page = 1, limit = 10, refresh = false) {
            if (this.getReports.length !== 0 && !refresh) {
                this.reports = [...this.getReports];
                this.isLoadingReportList = false;
                if (this.$route.params.reportId) {
                    this.activeReport = this.getReports.find(d => d.id === Number(this.$route.params.reportId))
                }
                return;
            }
            this.isLoadingReportList = true;
            const response = await this.$api.get(
                `Reports?page=${page}&limit=${limit}`
            );
            if (response.result === "OK" && response.message === "OK") {
                this.reports = response.data;
                this.$store.commit('SET_REPORT_LIST', response.data)
                if (this.$route.params.reportId) {
                    this.activeReport = response.data.find(d => d.id === Number(this.$route.params.reportId))
                }
            }
            else this.reports = [];
            this.isLoadingReportList = false;
        },
        SET_SELECTED_FORM_TYPE(row) {
            this.activeReport = { ...row, status: true };
        }
    },
    mounted() {
        this.GET_INITIAL_REPORT_LIST();
    }
}